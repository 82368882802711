import { useRef } from "react";
import { useQuery, useMutation, useQueryClient, useMutationState } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import isEqual from "react-fast-compare";
import { QuoteAPI } from "../utils/QuoteAPI";
import { QuoteDataUtils } from "../utils/QuoteDataUtils";
import { Quote } from "../types/Quote.interface";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { useThrottleOneAtATime } from "@/shared/hooks/useThrottleOneAtATime";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { PublicConfig } from "@/shared/PublicConfig";
import { PRIORITY_CODE_DEPRECATED } from "../types/SpotAPI";
import ErrorUtils from "../utils/ErrorUtils";
/**
 * `useQuote` Custom Hook
 *
 * This hook is designed to manage the fetching, updating, and default state of a Quote.
 *
 * @param quoteId - Optional. The ID of the quote to fetch.
 *
 * @returns
 *  - quoteQuery: The React Query object containing the current quote state, refetch, etc.
 *  - updateQuote: A mutation function to update the quote. Accepts a Quote object as parameter.
 *  - isQuoteUpdating: A boolean flag indicating if any quote mutation is currently pending.
 */

export type UseQuoteProps = {
    quoteId?: string;
    underwriter: PetUnderwriterType;
    setQuoteId?: (quoteId: string) => void;
    includeBirthProps?: boolean;
};

export const useQuote = ({ quoteId, underwriter, setQuoteId, includeBirthProps }: UseQuoteProps) => {
    // Ref to store the last quote data
    const lastQuoteDataRef = useRef<Quote | null>(null);
    const { updateAppState, appState } = useAppLayerContext();
    const { mockAPIError } = appState;

    const quoteApi = new QuoteAPI(underwriter);
    const queryClient = useQueryClient();
    const updateQuoteAPI = useThrottleOneAtATime(async (quoteData: Quote, quoteApi: QuoteAPI, queryParams?: { [key: string]: any }): Promise<Quote> => {
        try {
            const petQuote = QuoteDataUtils.quoteToPetQuote(quoteData);
            const updatedPetQuote = await quoteApi.updateQuote(petQuote, queryParams);
            const petQuoteToQuote = QuoteDataUtils.petQuoteToQuote(updatedPetQuote, underwriter, includeBirthProps);
            return petQuoteToQuote;
        } catch (error) {
            throw error;
        }
    });

    const quoteQuery = useQuery({
        queryKey: ["quote", quoteId],
        queryFn: async ({ queryKey }) => {
            const [, _quoteId] = queryKey;
            if (!_quoteId) return {};
            try {
                const petQuote = await quoteApi.getQuote(_quoteId);
                if (!!petQuote) {
                    const petQuoteToQuote = QuoteDataUtils.petQuoteToQuote(petQuote, underwriter, includeBirthProps);
                    return petQuoteToQuote;
                }
                return {};
            } catch (err) {
                if (isAxiosError(err)) throw err;
            }
        },
        retry: (failureCount, error) => {
            if (failureCount >= 2) return false; // Stop after 2 retries
            if (isAxiosError(error)) {
                // Only retry if the status code is >= 500
                return error.response?.status ? error.response.status >= 500 : false;
            }
            return false;
        },
        retryDelay: 1000
    });

    const updateQuote = useMutation<Quote, Error, Quote>({
        retry: (failureCount, error) => {
            // Ignore retry for specific errors:
            // Pcode error is retried outside of the mutate call
            const hasPcodeError = ErrorUtils.hasSpotError(error, PRIORITY_CODE_DEPRECATED);
            if (hasPcodeError) {
                return false;
            }

            // Retry for other errors
            return failureCount < 2; // Retry up to 2 times
        },
        retryDelay: 1000,
        mutationKey: [`quote`],
        mutationFn: async (quoteData: Quote) => {
            if (!!lastQuoteDataRef.current && isEqual(lastQuoteDataRef.current, quoteData)) {
                return lastQuoteDataRef.current;
            }
            try {
                let queryParams = {} as { [key: string]: any };

                if (PublicConfig.ENVIRONMENT === "development" && mockAPIError) {
                    queryParams = { sim: `error_id,${PRIORITY_CODE_DEPRECATED}` };
                    // Remove sim param if it's passed in as an argument via quoteData
                    // This is the "retry" scenario
                    if (quoteData?.extra?.hasOwnProperty("sim")) {
                        delete queryParams.sim;
                    }
                }

                const result = await updateQuoteAPI(quoteData, quoteApi, queryParams);
                if (!result) {
                    throw new Error("No result returned from updateQuoteAPI");
                }
                lastQuoteDataRef.current = result;
                return result;
            } catch (err) {
                if (isAxiosError(err)) throw err;
                throw new Error("Error updating quote");
            }
        },
        onSuccess: (data, variables) => {
            if (!quoteId && !!data.id && !!setQuoteId) {
                setQuoteId(data.id);
            }
            queryClient.setQueryData([`quote`, variables?.id], data);
        }
    });

    const mutationState = useMutationState({ filters: { status: `pending`, mutationKey: [`quote`] } });

    return { queryClient, quoteQuery, updateQuote, isQuoteUpdating: mutationState.length > 0 };
};
