import React from "react";
import { Dialog, DialogContent, DialogClose, DialogTitle, DialogDescription } from "@/shared/components/ui";
import { IconButton } from "@/shared/components/ui/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

interface ModalTemplateProps {
    onClose: () => void;
    title?: string;
    children: React.ReactNode;
}

const ModalTemplate: React.FC<ModalTemplateProps> = ({ onClose, title, children }) => {
    function handleOverlayClick() {
        onClose();
    }

    function handleContentClick(event: React.MouseEvent) {
        event.stopPropagation();
    }

    return (
        <Dialog open={true} onOpenChange={onClose}>
            <DialogContent onEscapeKeyDown={handleOverlayClick} onClick={handleContentClick}>
                <VisuallyHidden asChild>
                    <DialogTitle>{title ?? ""}</DialogTitle>
                </VisuallyHidden>
                <VisuallyHidden asChild>
                    <DialogDescription />
                </VisuallyHidden>
                <DialogClose asChild>
                    <IconButton onClick={onClose}>
                        <FontAwesomeIcon icon={faXmark} className="size-5 text-content-primary" />
                    </IconButton>
                </DialogClose>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default ModalTemplate;
