import Link from "next/link";
import React from "react";
import { Container } from "@/shared/components/Container";
import { cn } from "@/shared/utils";
import { CustomizationSlot } from "@/shared/components/CustomizationSlot";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { useAppLayerContext } from "../contexts/AppLayer";
import { useDirtyValues } from "@/shared/hooks/useDirtyValues";
import { PartialQuoteSchema, Quote } from "../types/Quote.interface";
import { UIUtils } from "../utils/UIUtils";
import Testimonials from "@/shared/components/Testimonials";

interface SiteFooterProps<StepIdType extends string> {
    formID: string;
    underwriter: PetUnderwriterType;
    links: { label: string; href: string }[];
    copyright: string;
    content?: React.ReactNode;
    extraContent?: React.ReactNode[];
    className?: string;
    // Defaults to false. If true, offsets footer content to avoid overlap with fixed content.
    hasOffset?: boolean;
    currentStep?: StepIdType;
    priorityCode?: string;
    showVideoTestimonials?: boolean;
}

export default function SiteFooter<StepIdType extends string>({
    formID,
    links,
    content,
    className,
    hasOffset = false,
    currentStep,
    extraContent,
    copyright,
    priorityCode,
    showVideoTestimonials
}: SiteFooterProps<StepIdType>) {
    const quote = useAppLayerContext().appState.quoteQuery?.data;
    const { dirtyValues } = useDirtyValues<Quote>();
    const builderData = UIUtils.deepMerge(quote ?? {}, dirtyValues, PartialQuoteSchema).mergedBase as Quote;

    const year = new Date().getFullYear();
    const copyrightText = copyright.replaceAll("%year%", String(year));
    const conditionalClasses = {
        "pb-20 sm:pb-[104px]": hasOffset // offsets fixed price widget so that bottom footer content is not blocked on mobile
    };

    return (
        <>
            {showVideoTestimonials && currentStep === "coverage" && <Testimonials />}
            <CustomizationSlot type="form-footer" data={builderData} formId={formID} formStepId={currentStep} priorityCode={priorityCode} />
            <footer className={cn("inset-x-0 mt-8 bg-background-primitive--brand-c-800 py-4", className)}>
                <div className={cn("flex flex-col gap-2 text-xs text-content-primitive--white", conditionalClasses)}>
                    {links && (
                        <Container>
                            <div className="mt-2 flex flex-row flex-wrap gap-6 md:mt-5 md:text-base">
                                {links.map((link, index) => (
                                    <Link key={index} href={link.href} target="_blank">
                                        {link.label}
                                    </Link>
                                ))}
                            </div>
                        </Container>
                    )}

                    <div className="mb-3 mt-4 border-b border-stroke-primitive--brand-c-700 md:mb-5 md:mt-3"></div>

                    <Container className="flex flex-col gap-4">
                        {content && content}
                        {!!extraContent?.length && extraContent.map((content, index) => <React.Fragment key={`extra-content-${index + 1}`}>{content}</React.Fragment>)}
                        <div className=" leading-5">© {copyrightText}</div>
                    </Container>
                </div>
            </footer>
        </>
    );
}
