"use client";

import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/shared/utils";

const formHintVariants = cva("mt-2 text-xs", {
    variants: {
        variant: {
            error: "text-content-danger",
            hint: "text-content-secondary",
            success: "text-content-success"
        }
    }
});

const FormHint = React.forwardRef<HTMLDivElement, VariantProps<typeof formHintVariants> & React.HTMLAttributes<HTMLDivElement>>(({ className, variant, ...props }, ref) => (
    <div ref={ref} className={cn(formHintVariants({ variant }), className)} {...props} />
));

FormHint.displayName = "FormHint";

export { FormHint, formHintVariants };
