import { useEffect, useRef, useCallback } from "react";
import { withThrottleOneAtATime } from "@/shared/utils/withThrottleOneAtATime";

export const useThrottleOneAtATime = <R, A extends any[]>(func: (...args: A) => Promise<R>) => {
    const callbackFunction = useRef(func);
    const throttleFunction = useRef<(...args: A) => Promise<R>>();

    // Initialize the throttle function in an effect
    useEffect(() => {
        throttleFunction.current = withThrottleOneAtATime<R, A>((...args) => {
            return callbackFunction.current(...args);
        });
    }, []); // Empty dependency array as this should only initialize once

    // Update the callback function when func changes
    useEffect(() => {
        callbackFunction.current = func;
    }, [func]);

    // Return a stable function using useCallback
    return useCallback((...args: A): Promise<R> => {
        if (!throttleFunction.current) {
            throw new Error("Throttle function not initialized");
        }
        return throttleFunction.current(...args);
    }, []);
};
