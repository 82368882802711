export const getDataDogRumScript = (
    clientToken: string,
    applicationId: string,
    serviceName: string,
    env: string,
    version: string,
    sessionSampleRate: number,
    sessionReplaySampleRate: number
) => `
(function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    d=o.createElement(u);d.async=1;d.src=n
    n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
})(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
window.DD_RUM.onReady(function() {
    window.DD_RUM.init({
        clientToken: "${clientToken}",
        applicationId: "${applicationId}",
        site: "datadoghq.com",
        service: "${serviceName}",
        env: "${env}",
        version: "${version}",
        sessionSampleRate: ${sessionSampleRate},
        sessionReplaySampleRate: ${sessionReplaySampleRate},
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input"
    });
})
`;

export const getDataDogLogsScript = (clientToken: string, serviceName: string, env: string, version: string) => `
(function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    d=o.createElement(u);d.async=1;d.src=n
    n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
})(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-logs.js','DD_LOGS')
window.DD_LOGS.onReady(function() {
    window.DD_LOGS.init({
        clientToken: "${clientToken}",
        service: "${serviceName}",
        env: "${env}",
        version: "${version}",
        site: "datadoghq.com",
        forwardErrorsToLogs: true,
        sessionSampleRate: 100
    });
})
`;
