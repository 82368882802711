import * as React from "react";
import { cn } from "@/shared/utils";
import { FormHint } from "./FormHint";

export type ErrorMessage = string | undefined;

interface FormFieldProps {
    children: React.ReactNode;
    error?: ErrorMessage;
    success?: React.ReactNode;
    hint?: string | React.ReactNode;
    errorId?: string;
    className?: string;
    formHintProps?: React.HTMLAttributes<HTMLDivElement>;
    showGiftCardWrapper?: boolean;
}

type HintVariant = "error" | "success" | "hint";

const FormField = (props: FormFieldProps) => {
    const { children, error, success, hint, className, errorId, showGiftCardWrapper } = props;
    let hintText: string | React.ReactNode = hint;
    let hintVariant: HintVariant = "hint";
    let hintAttributes = {};

    // Set hintText and hintVariant based on the presence of error or success props
    if (error) {
        hintText = error;
        hintVariant = "error";
        hintAttributes = { id: errorId, role: "alert", "aria-live": "polite" };
    } else if (success) {
        hintText = success;
        hintVariant = "success";
    }

    return (
        <div className={cn("relative flex flex-col", className)}>
            {children}
            {hintText && (
                <FormHint variant={hintVariant} {...hintAttributes}>
                    {hintText}
                </FormHint>
            )}
        </div>
    );
};

FormField.displayName = "FormField";

export { FormField };
