/**
 * This creates a function that cannot be executed in parallel — in other words, any previous invocation must finish before a new invocation
 * can start. But it does not create an invocation queue— all waiting invocations of this function will be executed with the most
 * recent set of arguments passed to the function and will be executed only once.
 *
 * This can be used in cases where you have a UI that modifies an object and sends the latest version of the object to an API endpoint, but
 * you want to prevent multiple simultaneous calls to the API at one time. This ensures that the API is always called with the latest version
 * of the object, but only if/when the previous update has finished.
 *
 * It adheres to the following pattern:
 *
 * invocation1(paramsA) -> promise1 = function(paramsA)
 * invocation2(paramsB) -> promise2 = waits
 * invocation3(paramsC) -> promise2 = waits // Overwrites paramsB— paramsC will be used when the function is called next
 * promise1 finishes -> returns function(paramsA)
 * promise2 starts -> function(paramsC) // paramsC is most-recent set of params passed to function
 * promise2 finishes -> return function(paramsC)
 *
 * Note 1: invocation2 and invocation3 both point to promise2. So they will both finish at the same time and contain the
 * same return value.
 *
 * Note 2: keep in mind that this function maintains its own state and is not global. So in order for it to work it must
 * be generated outside of a react hook function or within a useRef hook. For example
 *
 * const myFunc1 = withThrottleOneAtATime(...);
 *
 * function myReactComponent()
 * {
 *     const myFunc2 = useRef(withThrottleOneAtATime(...)).current;
 *     // Both myFunc1 and myFunc2 can be used anywhere in the react component.
 *
 *     // This would not work because the function will be recreated every time the react component is re-rendered
 *     // and it would not be able to know if a previous function was being called.
 *     const myFunc3 = withThrottleOneAtATime(...);
 * }
 *
 *
 * @param func
 */
export const withThrottleOneAtATime = <R, A extends any[]>(func: (...args: A) => Promise<R>) => {
    let mostRecentArgs: A | undefined;
    let mainPromise: Promise<R> | undefined;
    let nextPromiseResolve: ((value: R) => void) | undefined;
    let nextPromiseReject: ((err: any) => void) | undefined;
    let nextPromise: Promise<R> | undefined;

    function processMainPromiseFinish() {
        mainPromise = undefined;

        // If there is a next promise, execute it now and make it the new main promise.
        if (nextPromise && mostRecentArgs) {
            mainPromise = func(...mostRecentArgs);
            mainPromise.then(nextPromiseResolve);
            mainPromise.catch(nextPromiseReject);
            mainPromise.finally(processMainPromiseFinish);
        }

        nextPromise = undefined;
        nextPromiseResolve = undefined;
        nextPromiseReject = undefined;
    }

    return (...args: A) => {
        mostRecentArgs = args;

        // If there is already a running invocation, return a promise that will execute when the main invocation ends.
        if (mainPromise) {
            if (!nextPromise) {
                nextPromise = new Promise<R>((resolve, reject) => {
                    nextPromiseResolve = resolve;
                    nextPromiseReject = reject;
                });
            }
            return nextPromise;
        }

        // Execute the main promise. We should only get here if there are no other invocations currently running.
        mainPromise = func(...args);

        // The main promise is finished. Now we check to see if we need to execute our next promise.
        mainPromise.finally(processMainPromiseFinish);

        return mainPromise;
    };
};
