"use client";
import React, { createContext, useContext, useState } from "react";
import ModalTemplate from "../components/ModalTemplate";

interface ModalData {
    id: string;
    content: React.ReactNode;
    title?: string;
}

interface ModalContextType {
    showModal: (id: string, content: React.ReactNode, title?: string) => void;
    closeModal: (id: string) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ModalProviderProps {
    children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
    const [modals, setModals] = useState<ModalData[]>([]);

    const showModal = (id: string, content: React.ReactNode, title?: string): void => {
        setModals(prevModals => [...prevModals, { id, content, title }]);
    };

    const closeModal = (id: string): void => {
        setModals(prevModals => prevModals.filter(modal => modal.id !== id));
    };

    return (
        <ModalContext.Provider value={{ showModal, closeModal }}>
            {children}
            {modals.map(modal => (
                <ModalTemplate key={modal.id} onClose={() => closeModal(modal.id)} title={modal.title}>
                    {modal.content}
                </ModalTemplate>
            ))}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModal must be used within a ModalProvider");
    }
    return context;
};
