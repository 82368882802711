import * as React from "react";
import { cn } from "../utils";

interface ContainerProps {
    children: React.ReactNode;
    className?: string;
}

const Container = (props: ContainerProps) => {
    const { children, className } = props;

    return <div className={cn("mx-auto w-full max-w-content px-4 sm:px-6 lg:px-10 xl:p-0", className)}>{children}</div>;
};

Container.displayName = "Container";

export { Container };
