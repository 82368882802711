import React, { useState, useEffect } from "react";

export interface StepperConfig<StepIdType extends string> {
    stepValue: StepIdType;
    shouldSkip?: boolean;
}

export interface StepperProps<StepIdType extends string> {
    currentStep: StepIdType;
    steps: StepperConfig<StepIdType>[];
    updateCurrentStep: (stepId: StepIdType, quoteId?: string) => void;
}

export const Stepper = <StepIdType extends string>({ currentStep, steps, updateCurrentStep }: StepperProps<StepIdType>) => {
    // Filter out steps that should be skipped
    const filteredSteps = steps.filter(step => !step.shouldSkip);

    const [stepStatus, setStepStatus] = useState(
        filteredSteps.map((step, index) => ({
            ...step,
            isClickable: index === 0 // initially only the first step is clickable
        }))
    );

    useEffect(() => {
        const currentIndex = filteredSteps.findIndex(step => step.stepValue === currentStep);
        setStepStatus(prevStepStatus => {
            // Only update stepStatus if it has changed
            const newStepStatus = filteredSteps.map((step, index) => ({
                ...step,
                isClickable: index <= currentIndex
            }));

            // Check if the statuses are actually different before updating state
            const isEqual = prevStepStatus.length === newStepStatus.length && prevStepStatus.every((status, i) => status?.isClickable === newStepStatus[i]?.isClickable);

            if (isEqual) {
                return prevStepStatus;
            }
            return newStepStatus;
        });
    }, [currentStep, filteredSteps]);

    const handleGoToStep = (stepValue: StepIdType) => {
        const currentIndex = filteredSteps.findIndex(step => step.stepValue === currentStep);
        const targetIndex = filteredSteps.findIndex(step => step.stepValue === stepValue);
        if (targetIndex > currentIndex) return; // Don't allow navigating to a future step directly
        updateCurrentStep(stepValue);
    };

    return (
        <nav aria-label="Progress" className="mb-7 mt-2 w-full">
            <ol className="flex space-x-1">
                {stepStatus.map(({ stepValue, isClickable }, index) => {
                    // Determine if the step is complete or not
                    const currentIndex = filteredSteps.findIndex(step => step.stepValue === currentStep);
                    const isComplete = index <= currentIndex;

                    return (
                        <li key={stepValue} className="flex w-full flex-col justify-center">
                            <button type="button" className={`flex w-full items-center`} disabled={!isClickable} onClick={() => handleGoToStep(stepValue)}>
                                <div className={`h-2 w-full rounded ${isComplete ? "bg-[#003E52]" : "bg-[#E5E7EB]"}`}></div>
                                <span className="sr-only">{`Step ${stepValue}`}</span>
                            </button>
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};
