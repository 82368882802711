import React from "react";
import Image from "next/image";
import { Heading } from "./ui/Heading";
import { Badge } from "./ui/Badge";
import { useModal } from "../contexts/ModalProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { useMediaQuery } from "@react-hookz/web";
import { UIUtils } from "../utils/UIUtils";

type CardData = {
    className?: string;
    title: string;
    subTitle: string;
    summary: string;
    pillContent: string;
    videoUrl: string;
    mobileVideoUrl: string;
};

// Testimonials Data
const Testimonials: React.FC = () => {
    const cards: CardData[] = [
        {
            title: "Dianne",
            subTitle: "Cancer Treatment",
            summary: `"Remember, anything can happen"`,
            pillContent: "Met deductible in 2 months",
            videoUrl: "https://www.youtube.com/embed/2nQ6FKMq4Wg",
            mobileVideoUrl: "https://www.youtube.com/embed/1IFQ195K1Wk"
        },
        {
            title: "Rob & Obi",
            subTitle: "Broken Bone",
            summary: `"Spot made it so painless & easy for me."`,
            pillContent: "$2778 reimbursed",
            videoUrl: "https://www.youtube.com/embed/nW-i8LfzI44",
            mobileVideoUrl: "https://www.youtube.com/embed/Ssd7F-spgUg"
        }
    ];

    return (
        <div className="my-7 flex flex-col gap-4 px-5 text-center lg:my-10">
            <div>
                <Heading level="h2" className="text-lg font-bold">
                    Customer Stories
                </Heading>
                <p className="text-sm text-content-secondary">Meet our happy &quot;paw-rents&quot;</p>
            </div>
            <div className="flex w-full flex-col items-center gap-4 lg:flex-row lg:justify-center">
                {cards.map(card => (
                    <Card key={card.title} {...card} className="w-full max-w-[500px] transition duration-300 hover:scale-105 hover:shadow-lg" />
                ))}
            </div>
        </div>
    );
};

export default Testimonials;

const Card: React.FC<CardData> = ({ className, title, subTitle, summary, pillContent, videoUrl, mobileVideoUrl }) => {
    const { showModal } = useModal();

    const videoId = UIUtils.getVideoId(videoUrl);
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

    return (
        <div
            onClick={() => showModal(title, <YoutubeIframe url={videoUrl} mobileVideoUrl={mobileVideoUrl} />)}
            className={`flex cursor-pointer overflow-hidden rounded-2xl border border-stroke-primary ${className}`}
        >
            <div className="relative size-full min-h-[132px] max-w-[132px] bg-background-brand-primary sm:min-h-[175px] sm:max-w-[175px]">
                <div className="absolute inset-0 z-40 flex items-center justify-center bg-[rgba(0,0,0,0.5)]">
                    <div className="flex size-10 items-center justify-center rounded-full bg-background-brand-b-primary">
                        <FontAwesomeIcon icon={faPlay} className="relative left-[1px] text-2xl text-content-primary-invert" />
                    </div>
                </div>
                <Image src={thumbnailUrl} alt={`${title} thumbnail`} fill objectFit="cover" objectPosition="center" />
            </div>
            <div className="flex flex-col justify-between p-4">
                <div className="text-start">
                    <h3 className="text-lg font-bold text-content-primary sm:text-xl">{title}</h3>
                    <h4 className="text-sm font-medium text-content-secondary sm:text-base">{subTitle}</h4>
                    <p className="line-clamp-2 text-xs font-normal text-content-primary sm:text-sm">{summary}</p>
                </div>
                <Badge variant="ghost" className="w-fit bg-background-tertiary text-start text-[11px] sm:text-xs">
                    {pillContent}
                </Badge>
            </div>
        </div>
    );
};

const YoutubeIframe = ({ url, mobileVideoUrl }: { url: string; mobileVideoUrl: string }) => {
    const addVideoParams = (videoUrl: string, params: string) => {
        const separator = videoUrl.includes("?") ? "&" : "?";
        return `${videoUrl}${separator}${params}`;
    };

    const isMobileDevice = useMediaQuery("only screen and (max-width : 768px)");

    return (
        <iframe
            width="100%"
            height="500px"
            src={addVideoParams(isMobileDevice ? mobileVideoUrl : url, "autoplay=1")}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="pt-8"
        ></iframe>
    );
};
